import {
	CartItem,
	ItemSource,
	dismissItemError,
	getCartItem,
	getPriceForItem,
	modifyProduct,
	removeItem,
} from '../reducers/checkout.reducer';
import {
	getMyProductsItem,
	removeFromProductList,
	updateListProduct,
} from '../reducers/persisted-list.reducer';
import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { ConfirmDialogV2Component } from '@app/dialog/components/confirm-dialog-v2/confirm-dialog-v2.component';
import { getProductQuantity } from '@app/product/utils/product-utils';
import { popErrorToast } from '@app/shared/reducers/toast.reducer';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench';
import { pluckSuccessData } from '@granodigital/grano-remote-data';
import { firstValueFrom } from 'rxjs';

/** Type of cart item error. */
export enum CartItemErrorType {
	NotAvailable = 'NotAvailable',
	QuantityInvalid = 'QuantityInvalid',
	StockLevelTooLow = 'StockLevelTooLow',
	OptionsInvalid = 'ProductOptionsInvalid',
	PrintfileMissing = 'PrintfileMissing',
	PriceChange = 'PriceChange',
	PricingError = 'PricingError',
}

/** How long to wait before dismissing the error to avoid UI flicker. */
export const ERROR_DISMISS_DELAY = 100;

/** E.g. when a selected option has multiple selectable values. */
export abstract class CartItemError {
	abstract readonly type: CartItemErrorType;
	/** Localized error message. */
	abstract readonly message: string;
	/** Icon to display next to the error message. */
	abstract readonly icon: IconDefinition;
	/** Is the error blocking checkout? */
	abstract readonly isBlocker: boolean;
	/** Is fixable by the user? */
	abstract readonly isFixable: boolean;

	/** Unique key for the error. */
	get key(): string {
		return `${this.type}-${this.itemId}`;
	}

	constructor(
		/** The cart item ID. */
		public readonly itemId: string,
		/** The source of the item. */
		public readonly itemSource: ItemSource,
		/** Error trace to identify the error. */
		public readonly errorTrace?: string,
	) {}

	/** Attempt to fix the error. */
	async fix(injector: Injector): Promise<void> {
		// Inject services used by multiple error types for convenience.
		const store = injector.get<Store<RootReducer.State>>(Store<RootReducer.State>);
		const router = injector.get(Router);
		const dialog = injector.get(MatDialog);
		await this.applyFix({ store, router, dialog, injector });
	}

	/** Default implementation simply dismisses the error. */
	protected async applyFix({
		store,
	}: {
		store: Store<RootReducer.State>;
		router: Router;
		dialog: MatDialog;
		injector: Injector;
	}): Promise<void> {
		if (this.isFixable) this.dismiss(store);
		else throw new Error('Error is not fixable');
	}

	/** Dismiss error after a short time. */
	protected dismiss(store: Store<RootReducer.State>): void {
		setTimeout(() => {
			store.dispatch(dismissItemError(this));
		}, ERROR_DISMISS_DELAY);
	}

	/** Get the cart item from the store. */
	protected async getItem(store: Store<RootReducer.State>): Promise<CartItem> {
		const item = await firstValueFrom(
			this.itemSource === ItemSource.ShoppingCart
				? store.select(getCartItem(this.itemId))
				: store.select(getMyProductsItem(this.itemId)).pipe(pluckSuccessData),
		);
		if (!item) throw new Error(`Item not found: ${this.itemId}`);
		return item;
	}

	/** Update the item in the store. */
	protected updateItem(store: Store<RootReducer.State>, updatedItem: CartItem): void {
		const action =
			this.itemSource === ItemSource.MyProducts
				? updateListProduct('my-products', updatedItem)
				: modifyProduct(updatedItem.id, updatedItem.product, updatedItem.options, updatedItem.price);
		store.dispatch(action);
	}
}

/** When an item has missing or invalid options. */
export class CartItemNotAvailableError extends CartItemError {
	readonly type: CartItemErrorType = CartItemErrorType.NotAvailable;
	readonly message = $localize`:@@CartItemErrorNotAvailable:Product is no longer available.`;
	readonly dialogTitle = $localize`:@@CartItemErrorNotAvailableTitle:Product not available`;
	readonly icon = faCircleExclamation;
	readonly isBlocker = true;
	readonly isFixable = true;
	/** Fix by removing item from cart. */
	async applyFix({
		store,
		dialog,
	}: {
		store: Store<RootReducer.State>;
		dialog: MatDialog;
	}): Promise<void> {
		const confirmRemoval = await ConfirmDialogV2Component.open(dialog, {
			title: this.dialogTitle,
			description: $localize`:@@CartItemErrorNotAvailableMessage:Do you want to remove the product from the cart?`,
			acceptLabel: $localize`:@@CartItemErrorNotAvailableConfirm:Remove`,
			rejectLabel: $localize`:@@CartItemErrorNotAvailableCancel:Keep for now`,
		});
		if (confirmRemoval) {
			if (this.itemSource === ItemSource.ShoppingCart) store.dispatch(removeItem(this.itemId));
			else store.dispatch(removeFromProductList('my-products', { id: this.itemId }));
			this.dismiss(store);
		}
	}
}

/** When fetching price fails. */
export class CartItemPricingError extends CartItemNotAvailableError {
	readonly type = CartItemErrorType.PricingError;
	readonly message = $localize`:@@CartItemErrorPricingError:Could not fetch price.`;
	readonly dialogTitle = $localize`:@@CartItemErrorPricingErrorTitle:Price not available`;
}

/** When stock level is too low for the selected quantity. */
export class CartItemStockLevelTooLowError extends CartItemError {
	readonly type = CartItemErrorType.StockLevelTooLow;
	readonly message = $localize`:@@CartItemErrorStockLevelTooLow:Reduce quantity to available stock.`;
	readonly icon = faWrench;
	readonly isBlocker = true;
	readonly isFixable = true;

	constructor(
		/** The cart item ID. */
		itemId: string,
		/** The source of the item. */
		itemSource: ItemSource,
		/** The amount of stock missing (negative). */
		private readonly stockDelta: number,
	) {
		super(itemId, itemSource);
	}

	/** Fix by reducing the quantity. */
	async applyFix({ store }: { store: Store<RootReducer.State> }): Promise<void> {
		const item = await this.getItem(store);
		const quantity = getProductQuantity(item, 0);
		// If the stock deficit is bigger than the quantity, lowering does not work.
		if (this.stockDelta + quantity <= 0) {
			store.dispatch(
				popErrorToast({
					title: $localize`:@@CartItemErrorStockLevelTooLowRemove:There is not enough stock.`,
				}),
			);
			return;
		}
		const newItemOptions = {
			...item.options,
			selectedOptions: {
				...item.options.selectedOptions,
				quantity: quantity - Math.abs(this.stockDelta),
			},
		};
		// Calculate new price.
		store.dispatch(getPriceForItem(item, { body: newItemOptions.selectedOptions }));
		// Lower the quantity by the stock amount missing.
		this.updateItem(store, { ...item, options: newItemOptions });
		store.dispatch(dismissItemError(this));
	}
}

/** When product options have changed. */
export class CartItemOptionsInvalidError extends CartItemError {
	readonly type: CartItemErrorType = CartItemErrorType.OptionsInvalid;
	readonly message = $localize`:@@CartItemErrorProductOptionsInvalid:Update changed product options.`;
	readonly icon = faWrench;
	readonly isBlocker = true;
	readonly isFixable = true;
	/** Fix by opening product detail page, where user can update the settings. */
	async applyFix({ store, router }: { store: Store<RootReducer.State>; router: Router }): Promise<void> {
		const item = await this.getItem(store);
		// Open product detail page.
		void router.navigate(['/product', item.product.id, item.product.slug ?? '', 'edit', item.id], {
			queryParams: this.itemSource === ItemSource.MyProducts ? { productlist: '1' } : undefined,
		});
		store.dispatch(dismissItemError(this));
	}
}

/** When product printfile is not available. */
export class CartItemPrintfileMissingError extends CartItemOptionsInvalidError {
	readonly type = CartItemErrorType.PrintfileMissing;
	readonly message = $localize`:@@CartItemErrorPrintfileMissing:Printfile is missing or has expired, please update it.`;
}

/** When product price has changed. */
export class CartItemPriceChangeError extends CartItemError {
	readonly type = CartItemErrorType.PriceChange;
	readonly message = $localize`:@@CartItemErrorPriceChange:Product price has changed.`;
	readonly icon = faTriangleExclamation;
	readonly isBlocker = false;
	readonly isFixable = true; // Simply dismiss the error, the default action.
}
