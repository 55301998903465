import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import {
	SnackBarContentComponent,
	type SnackBarContentData,
} from '@app/shared/components/snackbar-content/snackbar-content.component';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { environment } from '@src/environments/environment';

export interface SnackBarConfig {
	/** Title for snackBar. */
	title: string;

	/** Body text for snackBar. */
	body?: string;

	/**
	 * Duration to show the snackBar.
	 *
	 * - `undefined`  Infer from configuration
	 * - `0`          Show until dismissed manually
	 * - `number`     Dismissed after specified time
	 */
	duration?: number;

	/**
	 * Duration to show the snackBar when `action` is configured.
	 * (`duration` overrides this)
	 *
	 * - `undefined`  Fall back to default 10000ms
	 * - `0`          Show until dismissed manually
	 * - `number`     Dismissed after specified time
	 */
	durationWithAction?: number;

	/**
	 * Duration to show the snackBar when `action` is not configured.
	 * (`duration` overrides this)
	 *
	 * - `undefined`  Fall back to default 5000ms
	 * - `0`          Show until dismissed manually
	 * - `number`     Dismissed after specified time
	 */
	durationWithoutAction?: number;

	/** Action button text. If empty, action button isn't shown. */
	action?: string;

	/** Called when action button is clicked */
	onAction?: (ctx: SnackBarService) => void;

	/**
	 * Enable dismissing the snackBar by clicking on it.
	 *
	 * - `undefined`  Dismiss on click if `action` isn't configured.
	 * - `true`       Always dismiss on click.
	 * - `false`      Never dismiss on click.
	 */
	dismissOnClick?: boolean;

	/** Vertical position of the snackBar */
	verticalPosition?: MatSnackBarConfig['verticalPosition'];
	/** Horizontal position of the snackBar */
	horizontalPosition?: MatSnackBarConfig['horizontalPosition'];

	/**
	 * Icon to show in the snackBar
	 * @deprecated It's strongly suggested to avoid showing icons in snackBars
	 */
	icon?: IconDefinition;

	/** Dismiss current snackBar and skip the queue to show this snackBar right away */
	immediate?: boolean;

	/** Called when snackBar is opened and visible */
	afterOpened?: (element: Element, SnackBarRef: SnackBarRef) => void;
	/** Called after snackBar is removed from view*/
	afterDismissed?: (type: DismissReason, SnackBarRef: SnackBarRef) => void;

	/** Extra classes to be added to the snackBar element */
	class?: string[];
}

export interface CompiledConfig {
	config: SnackBarConfig;
	matSnackBarConfig: MatSnackBarConfig<SnackBarContentData>;
}

export type SnackBarRef = MatSnackBarRef<SnackBarContentComponent>;
export interface CurrentData {
	dismissReason?: DismissReason;
	snackBarRef?: SnackBarRef;
	config?: SnackBarConfig;
	matSnackBarConfig: MatSnackBarConfig;
}
export enum DismissReason {
	User = 'user',
	Timeout = 'timeout',
	Click = 'click',
	Immediate = 'immediate',
	Clear = 'clear',
}

export const SNACKBAR_DEFAULT_CONFIG: Readonly<Partial<SnackBarConfig>> = {
	durationWithoutAction: 5000,
	durationWithAction: 10_000,
	verticalPosition: 'top',
	horizontalPosition: 'center',
};

/**
 * Convenience service for snackBar.
 *
 * Provides some extra features MatSnackBar doesn't do well or at all:
 * - Queue for showing multiple snackBars one at a time
 * - Icon support
 * - Styling for title and body text
 * - Click on snackBar to close
 */
@Injectable({ providedIn: 'root' })
export class SnackBarService {
	private baseConfig!: Partial<SnackBarConfig>;
	private readonly snackBarQueue: CompiledConfig[] = [];

	private current?: CurrentData;
	constructor(private readonly snackBar: MatSnackBar) {
		this.init({});
	}

	/** Initialize service with configuration */
	init(config: Partial<SnackBarConfig>): void {
		this.baseConfig = { ...SNACKBAR_DEFAULT_CONFIG, ...environment.snackBarConfig, ...config };
	}

	/** Opens or enqueues a snackBar */
	open(overrideConfig: SnackBarConfig): void {
		const compiledConfig = this.compileConfig(overrideConfig);
		this.snackBarQueue[compiledConfig.config.immediate ? 'unshift' : 'push'](compiledConfig);
		this.processQueue();
	}

	/** Dismisses the currently open snackBar (if any) */
	dismiss(reason: DismissReason = DismissReason.User): void {
		if (!this.current?.snackBarRef) return;

		this.current.dismissReason = reason;
		this.current.snackBarRef.dismiss();
	}

	/** Dismisses the current snackBar and clears the queue */
	clear(): void {
		this.snackBarQueue.length = 0;
		this.dismiss(DismissReason.Clear);
	}

	/**
	 * Opens the next snackBar from queue.
	 *
	 * Called during both opening and dismissing a snackBar.
	 *
	 * - Noop if snackBar is already open
	 * - Dismisses currently open SnackBar if next snackBar is immediate
	 *
	 */
	private processQueue() {
		// Queue empty - do nothing.
		if (this.snackBarQueue.length === 0) return;

		const nextSnackBar = this.snackBarQueue[0];
		if (this.current) {
			// snackBar currently open.
			// Immediate requested - dismiss the old snackBar.
			if (nextSnackBar.config.immediate) this.dismiss(DismissReason.Immediate);
			return;
		}

		// No blockers. Open right away.
		this.snackBarQueue.shift();
		this.openSnackBar(nextSnackBar);
	}

	/**
	 * Compiles a usable config object from partial config
	 * @param additionalConfig Additional config for snackBar instance
	 * @returns Compiled config
	 */
	private compileConfig(additionalConfig: SnackBarConfig): CompiledConfig {
		const config = {
			...this.baseConfig,
			...(Object.fromEntries(
				Object.entries(additionalConfig).filter(([, value]) => value !== undefined),
			) as SnackBarConfig),
		};
		Object.assign(config, {
			dismissOnClick: config.dismissOnClick ?? !config.action,
			duration:
				config.duration ?? (config.action ? config.durationWithAction : config.durationWithoutAction),
			immediate: !!config.immediate,
		});

		const panelClass = (config.class && [...config.class]) || [];
		if (config.dismissOnClick) panelClass.push('is-clickable');
		if (config.action) panelClass.push('has-action');
		if (config.icon) panelClass.push('has-icon');

		return {
			config,
			matSnackBarConfig: {
				data: {
					title: config.title,
					body: config.body,
					action: config.action,
					onAction: config.onAction?.bind(this, this),
					icon: config.icon,
				},
				panelClass,
				duration: config.duration,
				verticalPosition: config.verticalPosition,
				horizontalPosition: config.horizontalPosition,
			},
		};
	}

	/**
	 * Calls MatSnackBar's openFromComponent and sets `this.current`.
	 *
	 * Used internally to actually open the snackBar.
	 * @param compiledConfig Config object
	 */
	private openSnackBar(compiledConfig: CompiledConfig) {
		const snackBarRef = this.snackBar.openFromComponent(
			SnackBarContentComponent,
			compiledConfig.matSnackBarConfig,
		);

		this.current = {
			snackBarRef,
			...compiledConfig,
			dismissReason: undefined,
		};

		snackBarRef.afterOpened().subscribe(() => this.afterOpened(this.current));
		snackBarRef.afterDismissed().subscribe(() => this.afterDismissed(this.current));
	}

	/**
	 * Extends opened snackBar
	 */
	private afterOpened(current?: CurrentData) {
		if (this.current !== current) return;
		// TODO: Instead of DOM manipulation, handle this in the component and pass the dismissOnClick option via snackbar content.
		const snackBarContainer = document.querySelector('.mat-mdc-snack-bar-container');
		if (!snackBarContainer) return;
		if (current?.config?.dismissOnClick)
			snackBarContainer?.addEventListener('click', () => this.dismiss(DismissReason.Click));
		if (current?.snackBarRef) current?.config?.afterOpened?.(snackBarContainer, current.snackBarRef);
	}

	/**
	 * Cleanup and trigger next snackBar from queue
	 */
	private afterDismissed(current?: CurrentData) {
		if (current?.snackBarRef)
			current.config?.afterDismissed?.(
				current.dismissReason ?? DismissReason.Timeout,
				current.snackBarRef,
			);
		this.current = undefined;
		this.processQueue();
	}
}
