import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init } from '@app/api/gateway/index';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string;
if (environment.cdnUrl) __webpack_public_path__ = `${environment.cdnUrl}/${$localize.locale}/`;

init({ fetchOptions: { credentials: 'include', getAuthorization: undefined } });

if (environment.production) {
	enableProdMode();
}

if (!environment.production) {
	// ng.getElement($0) can be used to debug components. You can use rxjs to read the observable data.
	void import('rxjs').then((rxjs) => {
		(window as any).rxjs = rxjs;
		(window as any).firstValueFrom = rxjs.firstValueFrom;
	});
}

void platformBrowserDynamic().bootstrapModule(AppModule);
