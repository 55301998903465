/* eslint-disable jsdoc/require-jsdoc */
// istanbul ignore file
import { Injectable } from '@angular/core';
import type { Agent } from '@newrelic/browser-agent/loaders/agent';
import Debug from 'debug';

const debug = Debug('ecom:NewRelic');

/** This is a mock of the NewRelic service, it's used if the real agent is not available. */
@Injectable({ providedIn: 'root' })
export class NewRelic implements Partial<Agent> {
	setCustomAttribute(name: string, value: string | number | null): void {
		debug('setCustomAttribute', name, value);
	}

	addPageAction(name: string, attributes: Record<string, string | boolean | number>): void {
		debug('addPageAction', name, attributes);
	}

	noticeError(error: unknown, customAttributes?: Record<string, unknown>): void {
		debug('noticeError', error, customAttributes);
	}

	setErrorHandler(handler: (error: Error | string) => boolean | { group: string }): void {
		debug('setErrorHandler', handler);
	}

	setApplicationVersion(value: string): void {
		debug('setApplicationVersion', value);
	}

	setUserId(value: string | null): void {
		debug('setUserId', value);
	}
}

export const NewRelicProvider = {
	provide: NewRelic,
	useFactory(): NewRelic {
		// TODO: Make it possible to enable debug logging even if window.newrelic is set.
		return window.newrelic || new NewRelic();
	},
};

export const NewRelicMockProvider = {
	provide: NewRelic,
	useFactory: (): NewRelic => ({
		setCustomAttribute: jest.fn(),
		addPageAction: jest.fn(),
		noticeError: jest.fn(),
		setErrorHandler: jest.fn(),
		setApplicationVersion: jest.fn(),
		setUserId: jest.fn(),
	}),
};
