import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Observable, firstValueFrom } from 'rxjs';

export interface ConfirmDialogData {
	title?: string;
	description?: string;
	acceptLabel?: string;
	rejectLabel?: string;
}

const defaultConfirmDialogData: ConfirmDialogData = {
	title: $localize`:@@ConfirmDialogTitle:Are you sure you want to proceed?`,
	description: '',
	acceptLabel: $localize`:@@ConfirmDialogContinueButton:Continue`,
	rejectLabel: $localize`:@@ConfirmDialogCancelButton:Cancel`,
};

/** Confirmation dialog */
@Component({
	standalone: true,
	imports: [MatDialogModule, MatButtonModule],
	selector: 'g-confirm-dialog-v2',
	templateUrl: './confirm-dialog-v2.component.html',
	styleUrls: ['./confirm-dialog-v2.component.scss'],
})
export class ConfirmDialogV2Component {
	/** Open a confirmation dialog. */
	static open(dialog: MatDialog, data: ConfirmDialogData): Promise<boolean> {
		return firstValueFrom(
			dialog.open(ConfirmDialogV2Component, { data }).afterClosed() as Observable<boolean>,
		);
	}

	readonly data: ConfirmDialogData = { ...defaultConfirmDialogData, ...this.dialogData };
	constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: ConfirmDialogData) {}
}
