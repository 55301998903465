@let orderPrice = orderPrice$ | async | foldSuccess;
<div class="price-summary-content">
	<div
		class="total-price-breakdown"
		*ngIf="(showPriceBreakdown && (products$ | async)?.length) || isConfirmedOrder"
	>
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Product price (no tax):</strong> </div>
			<div>
				<span class="total-price">{{ orderPrice?.productsPriceWithoutTax | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row" *ngIf="showCarbonNeutralCompensation">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong i18n="@@CarbonNeutralCompensationLabel">Carbon neutral product (no tax):</strong>
				<span>&nbsp;</span>
				<button mat-icon-button class="clear-button" (click)="popover('carbonNeutralCompensation')">
					<g-icon [icon]="icons.faCircleQuestion" />
				</button>
			</div>
			<div>
				<span class="total-price">{{ orderPrice?.carbonNeutralCompensation | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row" *ngIf="showEnergyAndServiceFee">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong i18n="@@EnergyAndServiceFeeLabel">Energy and service fee (no tax):</strong>
				<span>&nbsp;</span>
				<button mat-icon-button class="clear-button" (click)="popover('energyAndServiceFee')">
					<g-icon [icon]="icons.faCircleQuestion"
				/></button>
			</div>
			<div>
				<span class="total-price">{{ orderPrice?.energyAndServiceFee | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row" *ngIf="discount">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong>
					<span i18n>Discount</span>
					<span *ngIf="discount.type === 'percent'"> ({{ discount.percentage }} %)</span>:
				</strong>
			</div>
			<div>
				<span class="total-price">- {{ discount.amount }} €</span>
			</div>
		</div>
		<div fxLayout="row" *ngIf="orderPrice?.additionalFee !== '0.00'">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong><span i18n>Additional fees (no tax):</span></strong>
			</div>
			<div>
				<span class="total-price">{{ orderPrice?.additionalFee | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row" *ngIf="orderPrice?.handlingFee !== '0.00'">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Handling (no tax):</strong> </div>
			<div>
				<span class="total-price">{{ orderPrice?.handlingFee | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Shipping (no tax):</strong> </div>
			<div>
				<span class="total-price">{{ orderPrice?.shippingPrice | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Billing (no tax):</strong> </div>
			<div>
				<span class="total-price">{{ orderPrice?.billingPrice | number: '1.2-2' }} €</span>
			</div>
		</div>
		@for (taxRow of taxRows; track $index) {
			<div fxLayout="row" class="tax-row">
				<div fxFlexAlign="center" fxFlex="grow">
					<strong i18n="Tax amount (xx %):">Tax amount </strong
					><strong>({{ taxRow.percentage }} %):</strong>
				</div>
				<div>
					<span class="total-price">{{ taxRow.amount | number: '1.2-2' }} €</span>
				</div>
			</div>
		}
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Total tax:</strong> </div>
			<div>
				<span class="total-price">{{ orderPrice?.taxes?.total | number: '1.2-2' }} €</span>
			</div>
		</div>
	</div>

	<div
		class="discount-container"
		*ngIf="discount && !showPriceBreakdown && (products$ | async)?.length"
		fxLayout="row"
		fxFlexAlign="center"
	>
		<div fxFlexAlign="start" fxFlex="68 1 auto">
			<strong>
				<span i18n>Discount</span>
				<span *ngIf="discount.type === 'percent'"> ({{ discount.percentage }} %)</span>:
			</strong>
		</div>
		<div fxFlex="1 1 auto"></div>
		<div class="total-price-wrapper" fxFlex="1 1 auto">
			<span class="total-price">- {{ discount.amount }} €</span>
		</div>
	</div>
	<div
		class="total-price-container"
		*ngIf="(products$ | async)?.length"
		fxLayout="row"
		fxFlexAlign="center"
	>
		<div fxFlexAlign="start" fxFlex="68 1 auto">
			<strong *ngIf="showPriceBreakdown" i18n>Total (including tax):</strong>
			<strong *ngIf="!showPriceBreakdown" i18n>Total:</strong>
		</div>
		<div
			class="total-price-wrapper"
			fxFlex="1 1 auto"
			[ngClass]="{ 'is-loading': orderPrice$ | async | isPending }"
			data-cy="totalPriceContainer"
		>
			<g-icon [icon]="icons.faSpinner" pulse />
			<span *ngIf="showPriceBreakdown" class="total-price">
				{{ orderPrice?.totalPrice | number: '1.2-2' }} €
			</span>
			<span *ngIf="!showPriceBreakdown && (showPricesWithTax$ | async)" class="total-price">
				{{ orderPrice?.discountedProductsPriceWithAdditionalFee | number: '1.2-2' }}
				€
			</span>
			<span *ngIf="!showPriceBreakdown && !(showPricesWithTax$ | async)" class="total-price">
				{{ orderPrice?.discountedProductsPriceWithAdditionalFeeWithoutTax | number: '1.2-2' }}
				€
			</span>
		</div>
	</div>
	<g-vat-selector *ngIf="!showPriceBreakdown && (products$ | async)?.length && showPrices" />
</div>
