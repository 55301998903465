import { getToast } from '../reducers/toast.reducer';
import { SnackBarService, type SnackBarConfig } from './snackbar.service';
import { Injectable } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface ToastOptions {
	title: string;
	body?: string;
	type?: string;
	immediate?: boolean;
	icon?: IconDefinition;
	timeout?: number;
	showCloseButton?: boolean;
	errorTrace?: string;
	action?: string;
	onAction?: (service: SnackBarService) => void;
}

/** Toast service. */
@Injectable({ providedIn: 'root' })
export class ToastService {
	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly snackBar: SnackBarService,
	) {}

	/** Initialize the toast service. */
	init(): void {
		this.store.select(getToast).subscribe((toast) => {
			if (toast?.type) this.popToast(toast);
			else this.clear();
		});
	}

	/** Pop a toast. */
	popToast(options: ToastOptions): void {
		const {
			body,
			title,
			type = 'info',
			immediate = type === 'error',
			timeout,
			icon,
			showCloseButton,
			errorTrace,
			action,
			onAction,
		} = options;

		const config: SnackBarConfig = {
			body,
			title,
			immediate,
			duration: timeout,
			class: ['toast', `toast-${type}`],
			icon,
			action,
			onAction,
			// Set/override action if showCloseButton is true.
			...(showCloseButton && {
				action: $localize`:@@DismissToast:Dismiss`,
				onAction: (service) => service.dismiss(),
			}),
			...(errorTrace && {
				duration: 0, // Do not auto-dismiss if errorTrace is present.
				action: $localize`:@@ToastCopyTrace:Copy Trace`,
				body: `${$localize`:@@ToastErrorTrace:Trace:`} ${errorTrace}`,
				onAction: (service) => {
					void navigator.clipboard.writeText(errorTrace);
					setTimeout(() => service.dismiss(), 10_000);
				},
			}),
		};
		this.snackBar.open(config);
	}

	/** Clear the toast. */
	clear(): void {
		this.snackBar.clear();
	}
}
