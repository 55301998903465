import type { ToastOptions } from '../services/toast.service';
import type { RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

export interface State {
	toast?: ToastOptions;
}

export const initialState: State = {
	toast: undefined,
};

export const POP_TOAST = 'mygrano/toaster/POP_TOAST';
export const popToast = (options: ToastOptions) => ({ type: POP_TOAST, payload: { options } }) as const;

export type PopToastAction = ReturnType<typeof popToast>;

export const popErrorToast = (options: ToastOptions) =>
	popToast({ ...options, type: 'error', timeout: 30_000 });
export const popStickyErrorToast = (options: ToastOptions) =>
	popToast({ ...options, type: 'error', timeout: 0, showCloseButton: true });
export const popSuccessToast = (options: ToastOptions) =>
	popToast({ ...options, type: 'success', timeout: 5000 });
export const popInfoToast = (options: ToastOptions) =>
	popToast({ ...options, type: 'info', timeout: 15_000 });

export const CLOSE_ALL_TOASTS = 'mygrano/toaster/CLOSE_ALL_TOASTS';
export const closeAllToasts = () => ({ type: CLOSE_ALL_TOASTS }) as const;

type ToastAction = ReturnType<typeof popToast | typeof closeAllToasts>;

/** Toast reducer */
export function reducer(state: State = initialState, action: ToastAction): State {
	switch (action.type) {
		case POP_TOAST:
			return { ...state, toast: { ...action.payload.options } };
		case CLOSE_ALL_TOASTS:
			return { ...state, toast: undefined };
		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.toast;
export const getToast = setName(
	'getToast',
	createSelector(getState, (state: State) => state.toast),
);
