import { IconComponent } from '../icon/icon.component';
import { VatSelectorComponent } from '../vat-selector/vat-selector.component';
import { AsyncPipe, DecimalPipe, NgClass, NgIf } from '@angular/common';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { getPriceForOrder } from '@app/api/action/Price';
import { RootReducer, Store } from '@app/app.reducers';
import {
	ONLINE_SHIPPING_METHOD_SLUG,
	TaxRow,
	getCartItems,
	getDiscountCode,
	getOrderPrice,
	getPaymentMethod,
	getShippingMethod,
	getShowPricesWithTax,
	updateDiscountCode,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { ConfirmDialogV2Component } from '@app/dialog/components/confirm-dialog-v2/confirm-dialog-v2.component';
import { StoreDataService } from '@app/shared/services/store-data.service';
import { FoldSuccessPipe, IsPendingPipe } from '@app/shared/utils/template-helpers';
import { filterTruthy, getDebug } from '@app/shared/utils/util';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { Success, pluckSuccessData } from '@granodigital/grano-remote-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, merge } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

/** Convert a product from an existing order into a CartItem. */
function toCartItem(item: api.OrderProductDto): api.CartItemWithProductDto {
	const printfile = item?.printfile as api.PrintfileDto;
	return {
		product: { ...item.product!, id: item.product!.id || 0 },
		options: {
			workTitle: item.work_title,
			selectedOptions: item?.options?.selected_options,
			printfile,
		},
		id: item.uuid,
		price: item.price,
		isProcessing: false,
	};
}

/** Get the tax rows from the price object. */
export function getTaxRows(price?: api.OrderPriceDto): TaxRow[] {
	const taxRows: TaxRow[] = [];
	if (!price) return taxRows;
	const breakdown = (price?.taxes?.breakdown ?? {}) as Record<string, string>;
	for (const taxMultiplier of Object.keys(breakdown)) {
		taxRows.push({ percentage: taxMultiplier, amount: breakdown[taxMultiplier] });
	}
	return taxRows;
}
// Links
export const carbonNeutralCompensationMarketingPageUrl = $localize`:@@CarbonNeutralCompensationMarketingPageUrl:
		https://www.grano.fi/yritys/ymp%C3%A4risto-ja-ekologisuus#hiilineutraalit`;
export const energyAndServiceFeePageUrl = $localize`:@@EnergyAndServiceFeePageUrl:
		https://www.grano.fi/tiedote-muutoksista-laskutuskaytannoissamme`;

/** A component that displays the price summary for an order. */
@UntilDestroy()
@Component({
	standalone: true,
	// TODO: Refactor to use built-in control flow e.g. @if etc.
	imports: [
		VatSelectorComponent,
		AsyncPipe,
		DecimalPipe,
		IconComponent,
		NgClass,
		NgIf,
		FlexLayoutModule,
		IsPendingPipe,
		FoldSuccessPipe,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	selector: 'g-price-summary',
	templateUrl: './price-summary.component.html',
	styleUrls: ['./price-summary.component.scss'],
})
export class PriceSummaryComponent implements OnInit, OnChanges {
	@Input() showPriceBreakdown = false;
	@Input() showPrices = true;
	@Input() isConfirmedOrder = false;
	@Input() order?: api.OrderDto;
	private readonly debug = getDebug('PriceSummaryComponent');
	// Products
	readonly orderProducts$ = new BehaviorSubject<api.CartItemWithProductDto[] | undefined>(undefined);
	readonly products$ = merge(this.store.select(getCartItems), this.orderProducts$.pipe(filterTruthy));
	// Prices
	readonly orderPriceChange$ = new BehaviorSubject<api.OrderPriceDto | undefined>(undefined);
	readonly orderPrice$ = merge(
		this.store.select(getOrderPrice),
		this.orderPriceChange$.pipe(
			filterTruthy,
			map((price) => new Success(price)),
		),
	);
	readonly showPricesWithTax$ = this.store.select(getShowPricesWithTax);
	showEnergyAndServiceFee = false;

	readonly icons = { faSpinner, faCircleQuestion };

	taxRows: TaxRow[] = [];
	readonly isDiscountEnabled = this.storeData.storeData.storefront?.show_voucher === true;
	showCarbonNeutralCompensation = false;
	discount?: { type: string; percentage?: string; amount?: string };

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly storeData: StoreDataService,
		private readonly dialog: MatDialog,
	) {}

	/** Handle changes to the input properties. */
	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.order) return;
		const price = this.order?.metadata?.total_price;
		const products = this.order?.products ?? [];
		this.orderProducts$.next(products.map(toCartItem));
		this.orderPriceChange$.next(price);
		this.processOrderPrice(price);
	}

	/** Initialize the component. */
	ngOnInit(): void {
		// Don't update the information if the order has already been confirmed.
		// istanbul ignore if -- difficult to test, but trivial
		if (this.isConfirmedOrder) return;

		// TODO: This should not be handled in the view component.
		combineLatest([
			this.products$.pipe(filter((items) => items.length > 0)),
			this.store.select(getPaymentMethod),
			this.store.select(getShippingMethod),
			this.store.select(getDiscountCode),
		])
			.pipe(untilDestroyed(this), delay(0)) // Fix ExpressionChangedAfterItHasBeenCheckedError
			// It is caused because the store contains all this information so the subscribe is called
			// synchronously causing the getPriceForOrder to be called which in turn updates the
			// isFetchingTotalPrice$, within the same change detection round, causing the error.
			.subscribe(([items, paymentMethod, shippingMethod, discountCode]) => {
				this.updatePrice(items, paymentMethod, shippingMethod, discountCode);
			});
		this.orderPrice$.pipe(pluckSuccessData, untilDestroyed(this)).subscribe(this.processOrderPrice);
	}

	/** Update the price. */
	updatePrice(
		items: api.CartItemWithProductDto[],
		paymentMethod?: string,
		shippingMethod?: string,
		discountCode?: string,
	): void {
		this.debug('updatePrice', items, paymentMethod, shippingMethod, discountCode);
		this.store.dispatch(
			getPriceForOrder({
				body: {
					items: items.map((item) => ({
						id: item.product.id,
						options: {
							printfile_key: item?.options?.printfile?.key,
							selected_options: item.options.selectedOptions,
							premedia_fix_requested: item?.options?.selectedOptions?.is_premedia_fix_requested,
						},
					})),
					metadata: {
						shipping_method:
							shippingMethod && shippingMethod !== ONLINE_SHIPPING_METHOD_SLUG
								? shippingMethod
								: undefined,
						billing_method: paymentMethod,
					},
					discount_code: discountCode,
				},
			}),
		);
	}

	/** Set the discount. */
	private setDiscount(price?: api.OrderPriceDto): void {
		if (this.isDiscountEnabled === false && !this.isConfirmedOrder) {
			if (!!price && typeof price.discount === 'string' && price.discount !== '0.00') {
				this.store.dispatch(updateDiscountCode(undefined));
			}
			this.discount = undefined;
			return;
		}
		if (!!price && typeof price.discount === 'string' && price.discount !== '0.00') {
			this.discount = {
				type: price.discountType!,
				percentage: `${Math.round(Number.parseFloat(price.discount) * 100)}`,
			};
			this.discount.amount = (
				price.discountType === 'amount'
					? Number.parseFloat(price.discountAmount ?? '0')
					: Number.parseFloat(price.productsPrice) - Number.parseFloat(price.discountedProductsPrice)
			).toFixed(2);
			return;
		}
		this.discount = undefined;
	}

	private readonly processOrderPrice = (price?: api.OrderPriceDto): void => {
		this.taxRows = getTaxRows(price);
		this.showCarbonNeutralCompensation = Number.parseFloat(price?.carbonNeutralCompensation ?? '0') > 0;
		this.showEnergyAndServiceFee = Number.parseFloat(price?.energyAndServiceFee ?? '0') > 0;
		this.setDiscount(price);
	};

	/** Handle the popover for carbon neutral compensation or energy and service fee. */
	async popover(type: 'carbonNeutralCompensation' | 'energyAndServiceFee'): Promise<void> {
		if (type === 'carbonNeutralCompensation') {
			const openLink = await ConfirmDialogV2Component.open(this.dialog, {
				title: $localize`:@@CarbonNeutralCompensationPopoverTitle:Carbon neutral compensation`,
				// eslint-disable-next-line max-len
				description: $localize`:@@CarbonNeutralCompensationInfoText:Carbon neutral products are products whose CO2-emissions related to its production have been calculated and compensated for by a carbon offset project.`,
				acceptLabel: $localize`:@@InfoLinkReadMore:Read more`,
				rejectLabel: $localize`:@@CarbonNeutralCompensationPopoverClose:Close`,
			});
			if (openLink) {
				globalThis.open(carbonNeutralCompensationMarketingPageUrl, '_blank');
			}
		}

		if (type === 'energyAndServiceFee') {
			const openLink = await ConfirmDialogV2Component.open(this.dialog, {
				title: $localize`:@@EnergyAndServiceFeeLabel:Energy and service fee`,
				// eslint-disable-next-line max-len
				description: $localize`:@@EnergyAndServiceFeeInfoText:The prolonged and strong rise in raw material, energy and logistics costs will increase costs in our operations significantly. In order for us to continue to secure quick and smooth services, we will have to pass on some of these costs to our customers.`,
				acceptLabel: $localize`:@@InfoLinkReadMore:Read more`,
				rejectLabel: $localize`:@@PopoverClose:Close`,
			});
			if (openLink) {
				globalThis.open(energyAndServiceFeePageUrl, '_blank');
			}
		}
	}
}
