/* istanbul ignore next */
export function doScrolling(elementY: number, duration = 1) {
	const startingY: number = window.scrollY;
	const diff: number = elementY - startingY;
	let start: number;

	requestAnimationFrame(function step(timestamp: number) {
		if (!start) start = timestamp;
		const time = timestamp - start;
		const percent: number = Math.min(time / duration, 1);
		window.scrollTo(0, startingY + diff * percent);

		if (time < duration) {
			requestAnimationFrame(step);
		}
	});
}

/* istanbul ignore next */
export function scrollToElementByFragment(elementRef, fragment) {
	if (!elementRef || !fragment) return;
	const elementY = elementRef.nativeElement.getBoundingClientRect().top;
	const startingY = document.body.getBoundingClientRect().top;
	// Wait for AppComponent.deActivate() to finish scrolling to top of page
	setTimeout(() => doScrolling(elementY - startingY, 200), 100);
}
