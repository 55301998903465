import { environment } from '@src/environments/environment';
import { FeatureFlags } from '@src/types/environment';

/**
 * Retrieves the value of a feature flag from the local storage or environment config.
 * @param key - The key of the feature flag to retrieve.
 * @returns The value of the feature flag.
 */
export function getFlag<K extends keyof FeatureFlags>(key: K): FeatureFlags[K] {
	const local = localStorage.getItem(key);
	// Prefer local storage over environment.
	return (local ? JSON.parse(local) : (environment.featureFlags?.[key] ?? false)) as FeatureFlags[K];
}
