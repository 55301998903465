import type { ActionObj, Dispatch, RootReducer } from '@app/app.reducers';
import { addUnsavedWork, removeUnsavedWork } from '@app/shared/reducers/unsaved-work.reducer';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

export enum FileUploadStatus {
	Uploading = 'uploading',
	Success = 'success',
	Error = 'error',
}

export enum UploadNotification {
	Warning,
	Success,
	Danger,
}

export interface State {
	// A temporary state to keep track of ongoing upload statuses.
	// TODO: Refactor to use RemoteData
	uploads: Record<string, FileUploadStatus>;
}

export const initialState: State = {
	uploads: {},
};

export const START_UPLOAD = 'mygrano/upload/START_UPLOAD';
export const startUpload = (key: string) =>
	setName('startUpload', (dispatch: Dispatch): void => {
		dispatch(addUnsavedWork('upload'));
		dispatch({
			type: START_UPLOAD,
			payload: { key, state: FileUploadStatus.Uploading },
		});
	});

export const FINISH_UPLOAD = 'mygrano/upload/FINISH_UPLOAD';
export const finishUpload = (key: string, state: FileUploadStatus) =>
	setName('finishUpload', (dispatch: Dispatch): void => {
		dispatch(removeUnsavedWork('upload'));
		dispatch({
			type: FINISH_UPLOAD,
			payload: { key, state },
		});
	});

export const finishUploadOnly = (key: string, state: FileUploadStatus) =>
	setName('finishUpload', (dispatch: Dispatch): void => {
		dispatch({
			type: FINISH_UPLOAD,
			payload: { key, state },
		});
	});

export interface UploadAction extends ActionObj {
	type: typeof START_UPLOAD | typeof FINISH_UPLOAD;
	payload: { key: string; state: FileUploadStatus };
}

export function reducer(state: State = initialState, action: UploadAction): State {
	switch (action.type) {
		case START_UPLOAD:
		case FINISH_UPLOAD:
			return {
				uploads: {
					...state.uploads,
					[action.payload.key]: action.payload.state,
				},
			};
		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.upload;
export const getIsUploading = setName(
	'getUploadState',
	createSelector(getState, (state: State) =>
		Object.values(state.uploads).some((status) => status === FileUploadStatus.Uploading),
	),
);
export const getIsUploadError = setName(
	'getIsUploadError',
	createSelector(getState, (state: State) =>
		Object.values(state.uploads).some((status) => status === FileUploadStatus.Error),
	),
);
export const getIsUploadSuccess = setName(
	'getIsUploadSuccess',
	createSelector(getState, (state: State) =>
		Object.values(state.uploads).some((status) => status === FileUploadStatus.Success),
	),
);
export const getUploads = setName(
	'getUploads',
	createSelector(getState, (state: State) => state.uploads),
);
export const getIsUploadDone = (fileKey: string) =>
	setName(
		'getIsUploadDone',
		createSelector(getState, (state: State) => state.uploads[fileKey] !== FileUploadStatus.Uploading),
	);
export const getIsUploadSuccessful = (fileKey: string) =>
	setName(
		'getIsUploadSuccessful',
		createSelector(getState, (state: State) => state.uploads[fileKey] === FileUploadStatus.Success),
	);
