import { setName } from '../utils/name-helper';
import { RootReducer } from '@app/app.reducers';
import { FilePropsFragment } from '@app/file-api/file-api.service';
import { createSelector } from 'reselect';

export interface State {
	/**
	 * Uploaded files, and the current status of all the checks (e.g. antivirus, preflight, etc...)
	 */
	files: Record<string, Partial<FileState>>;
}

export interface FileState {
	preflightProgress?: number;
	file?: FilePropsFragment;
}

export const initialState: State = {
	files: {},
};

export interface RemoveFileAction {
	type: typeof REMOVE_FILE;
	payload: { key: string; state: { key: string } };
}

export interface UpdateFileAction {
	type: typeof UPDATE_FILE;
	payload: { key: string; state: Required<Pick<FileState, 'file'>> };
}

export interface UpdatePreflightProgressAction {
	type: typeof UPDATE_PREFLIGHT_PROGRESS;
	payload: { key: string; state: Required<Pick<FileState, 'preflightProgress'>> };
}

export type Action = RemoveFileAction | UpdateFileAction | UpdatePreflightProgressAction;

export const UPDATE_FILE = 'mygrano/upload/UPDATE_FILE';
/* Updates a file, only updates the properties found in the payload. */
export const updateFile = (key: string, file: FilePropsFragment) => ({
	type: UPDATE_FILE,
	payload: { key, state: { file } },
});

export const REMOVE_FILE = 'mygrano/upload/REMOVE_FILE';
export const removeFile = (key: string) => ({
	type: REMOVE_FILE,
	payload: { key },
});

export const UPDATE_PREFLIGHT_PROGRESS = 'mygrano/upload/UPDATE_PREFLIGHT_PROGRESS';
export const updatePreflightProgress = (key: string, progress: number) => ({
	type: UPDATE_PREFLIGHT_PROGRESS,
	payload: { key, state: { preflightProgress: progress } },
});

export function reducer(state: State = initialState, action: Action): State {
	switch (action.type) {
		case UPDATE_FILE:
			return {
				...state,
				files: {
					...state?.files,
					[action.payload.key]: {
						...state?.files?.[action.payload.key],
						file: {
							...state?.files?.[action.payload.key]?.file,
							...action.payload.state.file,
						},
					},
				},
			};
		case REMOVE_FILE: {
			const { [action.payload.key]: removedFile, ...files } = state.files;
			return {
				...state,
				files,
			};
		}
		case UPDATE_PREFLIGHT_PROGRESS:
			return {
				...state,
				files: {
					...state?.files,
					[action.payload.key]: {
						...state?.files?.[action.payload.key],
						preflightProgress: action.payload.state?.preflightProgress ?? 0,
					},
				},
			};
		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.files;
export const getFile = (fileKey: string) =>
	setName(
		'getFile',
		createSelector(getState, (state: State) => {
			return Object.entries(state.files).find(([key]) => key === fileKey)?.[1]?.file;
		}),
	);

export const getPreflightProgress = (fileKey: string) =>
	setName(
		'getPreflightProgress',
		createSelector(getState, (state: State) => {
			return Object.entries(state.files).find(([key]) => key === fileKey)?.[1]?.preflightProgress;
		}),
	);
