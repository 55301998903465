import { AddressSummaryComponent } from '../address-summary/address-summary.component';
import { IconComponent } from '../icon/icon.component';
import { OrderProductListComponent } from '../order-product-list/order-product-list.component';
import { OrderStatusSwitchComponent } from '../order-status-switch/order-status-switch.component';
import { PriceSummaryComponent } from '../price-summary/price-summary.component';
import { DatePipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint';

export interface OrderSummaryOptions {
	showOrderDetails?: boolean;
	showOrderStatus?: boolean;
	showCopyToBasket?: boolean;
	showPrices?: boolean;
	isConfirmedOrder?: boolean;
}

/** A component that displays a summary of an order. */
@Component({
	standalone: true,
	imports: [
		PriceSummaryComponent,
		IconComponent,
		AddressSummaryComponent,
		OrderProductListComponent,
		OrderStatusSwitchComponent,
		DatePipe,
		NgIf,
	],
	selector: 'g-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent {
	@Input({ required: true }) readonly order!: api.OrderDto;
	@Input() readonly options?: OrderSummaryOptions;
	readonly icons = { faPrint };
	/** Print the order summary. */
	printReceipt(): void {
		print();
	}
}
