<h1 mat-dialog-title data-cy="dialogTitle">{{ data.title }}</h1>
@if (data.description) {
	<div mat-dialog-content>
		<p>{{ data.description }}</p>
	</div>
}
<div mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false" data-cy="dialogReject">{{ data.rejectLabel }}</button>
	<button
		mat-flat-button
		color="primary"
		[mat-dialog-close]="true"
		data-cy="dialogAccept"
		cdkFocusInitial
	>
		{{ data.acceptLabel }}
	</button>
</div>
