import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export const PREVIEW_TOKEN_QUERY_PARAM = 'preview-token';
export const PREVIEW_TOKEN_KEY = 'previewToken';

@Injectable({ providedIn: 'root' })
export class PreviewTokenService {
	public token$ = new BehaviorSubject<string>(null);
	private readonly sessionStorage = sessionStorage;

	constructor() {
		this.token$.next(this.sessionStorage.getItem(PREVIEW_TOKEN_KEY));
	}

	getTokenValue(): string {
		if (!this.token$.getValue() && this.sessionStorage.getItem(PREVIEW_TOKEN_KEY)) {
			// Value changed after init, update value
			this.token$.next(this.sessionStorage.getItem(PREVIEW_TOKEN_KEY));
		}
		return this.token$.getValue();
	}

	setTokenFromQueryParamMap(queryParamMap: ParamMap): void {
		this.setToken(queryParamMap.get(PREVIEW_TOKEN_QUERY_PARAM));
	}

	setToken(previewToken: string): void {
		if (typeof previewToken === 'string' && previewToken.length > 0) {
			this.sessionStorage.setItem(PREVIEW_TOKEN_KEY, previewToken);
			this.token$.next(previewToken);
		}
	}
}
