import { ProductProofImages, ProofImageService } from '../services/proof-image.service';
import { firstTruthy } from '../utils/util';
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { ItemSource } from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { FALLBACK_PRODUCT_IMAGE_SRC } from '@app/shared/common';

export const PROOF_IMAGE_PATTERN = /.*\/GetProofImage\/(.*)\/.*\/.*/;

/** Directive to handle invalid product images. */
@Directive({
	standalone: true,
	selector: '[gFallbackProductImage]',
})
export class FallbackProductImageDirective {
	@HostBinding('src') @Input({ required: true }) src!: string;
	@Input() fallbackStateId?: string;
	@Input() fallbackItemId?: string;
	@Input() fallbackItemSource?: ItemSource;

	constructor(private readonly proofImageService: ProofImageService) {}

	/** Update the image source on error. */
	@HostListener('error')
	updateImageSrc(): void {
		// If this is an expired proof image, regenerate it
		const match = PROOF_IMAGE_PATTERN.exec(this.src);
		if (this.fallbackStateId && this.fallbackItemId && match) {
			const [, userId] = match;
			(this.fallbackItemSource === ItemSource.ShoppingCart
				? this.proofImageService.regenerateCartItemProofImages(
						this.fallbackItemId,
						this.fallbackStateId,
						userId,
					)
				: this.proofImageService.regenerateMyProductProofImages(
						this.fallbackItemId,
						this.fallbackStateId,
						userId,
					)
			)
				.pipe(firstTruthy)
				.subscribe({
					next: (images: ProductProofImages) => {
						this.src = images.proofImageThumbnail;
					},
					error: () => {
						// Default to fallback image on errors
						this.src = FALLBACK_PRODUCT_IMAGE_SRC;
					},
				});
			return;
		}
		this.src = FALLBACK_PRODUCT_IMAGE_SRC;
	}
}
