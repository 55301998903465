import {
	ItemSource,
	dismissItemError,
	getCartItemErrors,
	getCartItems,
	getItemPrices,
	getPriceForItem,
	reportItemError,
	setCartItems,
} from '../reducers/checkout.reducer';
import { getMyProducts, hasInitialized, updateMyProducts } from '../reducers/persisted-list.reducer';
import { isPrintfileAvailable } from '../utils/checkout-utils';
import {
	CartItemNotAvailableError,
	CartItemOptionsInvalidError,
	CartItemPriceChangeError,
	CartItemPricingError,
	CartItemPrintfileMissingError,
} from './cart-item-errors';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getProductsByIdList as fetchProductsByIdList } from '@app/api/action/Product';
import { RootReducer, Store } from '@app/app.reducers';
import { ConfirmDialogV2Component } from '@app/dialog/components/confirm-dialog-v2/confirm-dialog-v2.component';
import {
	getAllProductsById,
	isError as isErrorProducts,
	isLoading as isLoadingProducts,
} from '@app/product/reducers/product.reducer';
import { popErrorToast } from '@app/shared/reducers/toast.reducer';
import { NewRelic } from '@app/shared/services/newrelic.service';
import {
	firstTruthy,
	getDebug,
	isObjectContained,
	isPlainObjectEqual,
	mapToRx,
	omit,
	pick,
} from '@app/shared/utils/util';
import {
	Pending,
	foldSuccess,
	isFailure,
	isResolved,
	pluckSuccessData,
} from '@granodigital/grano-remote-data';
import {
	Subject,
	Subscription,
	first,
	firstValueFrom,
	fromEvent,
	map,
	merge,
	share,
	switchMap,
	throttleTime,
	timer,
} from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

/** The interval (in milliseconds) at which the cart products should be refreshed. */
export const PRODUCT_REFRESH_INTERVAL = 60_000;
export const PRODUCT_REFRESH_THROTTLE = 1000;

type RefreshCartProductsKeys<T> = {
	[K in keyof T]: Required<T>[K] extends object
		? Required<T>[K] extends unknown[]
			? K & string
			: [K & string, (keyof Required<T>[K])[]]
		: K & string;
}[keyof T][];

/** Keys to update when refreshing product data */
const refreshCartProductsKeys = [
	'stock_level',
	[
		'metadata',
		[
			'product_group_id_v2',
			'sonet_number',
			'cost_center',
			'order_status',
			'work_queue',
			'additional_information',
			'internal_instructions',
			'distribution_information',
			'workflow',
			'automation_producttype',
			'production_workflow_definition',
			'production_time',
			'product_id',
			'unit',
		],
	],
	[
		'locale',
		[
			'product_name',
			'product_name_sub_text',
			'product_description',
			'technical_description',
			'internal_information',
			'shipping_info',
		],
	],
	['images', ['master']], // Thumbnail for cart
	['properties', ['imageKeys', 'printfiles', 'additionalFiles', 'selectableOptions']],
	'product_properties',
] satisfies RefreshCartProductsKeys<api.ProductDto>;

// Using the DTO directly causes index signature errors.
type CartItemProduct = Pick<api.CartItemProductDto, keyof api.CartItemProductDto>;

/** These product options are never updated. */
const selectedOptionsNotUpdated = [
	'order_quantity',
	'quantity',
	'is_premedia_fix_requested',
	'premedia_fix_request_message',
	'scope',
] as const;

/**
 * A service for shopping cart product data refresh.
 * @todo [ED-2470] Rename into `ProductDataRefreshService`
 */
@Injectable({ providedIn: 'root' })
export class ShoppingCartRefreshService {
	private readonly debug = getDebug('ShoppingCartRefreshService');
	private readonly refreshSentinel = new Subject<ItemSource>();
	private readonly refresh$ = this.startProductDataRefresh$();
	private subscription?: Subscription;

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly newRelic: NewRelic,
		private readonly router: Router,
		private readonly dialog: MatDialog,
	) {}

	/**
	 * Validates the cart items and asks the user to proceed if there are warnings.
	 * @returns True if the cart is valid and the order can be created, false otherwise.
	 */
	async validateCart(): Promise<boolean> {
		this.debug('Refreshing product data before validating cart items');
		await this.triggerRefresh(ItemSource.ShoppingCart);
		const itemErrors = await firstValueFrom(this.store.select(getCartItemErrors));

		// No errors, proceed with order creation.
		if (!itemErrors || itemErrors.size === 0) return true;

		// Check whether cart contains any blocking errors.
		const blockers = [...itemErrors.values()].filter((error) => !!error?.isBlocker);

		if (blockers.length > 0) {
			// If there are errors that require user intervention, show a toast and prevent order creation.
			this.store.dispatch(
				popErrorToast({
					title: $localize`:@@ToastOrderErrors:Some items in the cart have errors. Please fix them before continuing.`,
				}),
			);
			this.debug('Blocking errors found', blockers);
			this.newRelic.noticeError(new Error('Blockers in cart'), {
				blockers: blockers.map((e) => e.key).join('|'),
			});
			return false;
		}
		// If there are no blocking errors, ask the user if they want to proceed.
		return ConfirmDialogV2Component.open(this.dialog, {
			title: $localize`:@@ConfirmOrderWithWarningsTitle:Some items in the cart have warnings`,
			description: $localize`:@@ConfirmOrderWithWarningsBody:Do you want to place the order?`,
			acceptLabel: $localize`:@@ConfirmOrderWithWarningsAccept:Place order`,
			rejectLabel: $localize`:@@ConfirmOrderWithWarningsReject:Let me check first`,
		});
	}

	/** Triggers a manual refresh of the shopping cart products. */
	async triggerRefresh(source = this.determineItemSource()): Promise<void> {
		// Start the refresh process on the first trigger and keep it running forever.
		if (!this.subscription) {
			this.debug('Initializing refresh subscription');
			this.subscription = this.refresh$.subscribe();
		}
		if (!source) {
			this.debug('Skipping refresh', { source });
			return;
		}
		const refreshPromise = firstValueFrom(this.refresh$);
		this.refreshSentinel.next(source);
		return refreshPromise;
	}

	/**
	 * Starts the product data refresh process.
	 * This method returns an Observable that emits void values at a specified interval.
	 * The refresh is triggered only when the document is not hidden.
	 * If an error occurs during the refresh, it is caught and logged using New Relic.
	 */
	private startProductDataRefresh$(): Observable<void> {
		// Wait for the state to initialize before starting the refresh.
		return this.store.select(hasInitialized).pipe(
			firstTruthy,
			switchMap(() =>
				merge(
					timer(PRODUCT_REFRESH_INTERVAL, PRODUCT_REFRESH_INTERVAL).pipe(mapToRx(['Timer'] as const)),
					fromEvent(document, 'visibilitychange').pipe(mapToRx(['VisibilityChange'] as const)),
					this.refreshSentinel.pipe(map((source) => ['RefreshSentinel', source] as const)),
				),
			),
			// Make sure multiple changes are batched.
			throttleTime(PRODUCT_REFRESH_THROTTLE, undefined, { leading: true, trailing: true }),
			switchMap(async ([trigger, source]) => {
				try {
					if (document.hidden) return; // Skip refresh when the document is hidden.
					const itemSource = source ?? this.determineItemSource();
					if (!itemSource) throw new Error('Item source unknown');
					this.debug('Triggered refresh', trigger, itemSource);
					await this.refreshProducts(itemSource);
				} catch (err) {
					// Log any errors that occur during the refresh, but do not stop the refresh process.
					this.newRelic.noticeError(err);
				}
			}),
			share(),
		);
	}

	/** Determines the source of the items based on the current route. */
	private determineItemSource(): ItemSource | undefined {
		const currentUrl = this.router.url;
		if (currentUrl.includes('/checkout/my-products')) return ItemSource.MyProducts;
		if (currentUrl.startsWith('/checkout')) return ItemSource.ShoppingCart;
	}

	/** Updates cart products with data fetched from api. */
	private async refreshProducts(itemSource: ItemSource = ItemSource.ShoppingCart): Promise<void> {
		const productIds = new Set<number>();
		const items = await firstValueFrom(
			itemSource === ItemSource.ShoppingCart
				? this.store.select(getCartItems)
				: this.store.select(getMyProducts).pipe(
						pluckSuccessData,
						map((list) => list.products),
					),
		);
		this.debug('Refreshing product data', itemSource, items);
		// No items in cart, skip.
		if (items.length === 0) return;
		// Fetch updated product data
		for (const { product } of items) productIds.add(product.id);
		this.debug('Fetching products', productIds);
		this.store.dispatch(fetchProductsByIdList([...productIds].join(',')));
		this.debug('Fetching prices');
		const itemPrices = new Map(
			await Promise.all(
				items.map(async (item) => {
					// Get current price if available.
					const currentPrice = await firstValueFrom(
						this.store
							.select(getItemPrices)
							.pipe(map((prices) => foldSuccess(prices.get(item.id) ?? new Pending()))),
					);
					// Fetch new price.
					this.store.dispatch(getPriceForItem(item, { body: item.options.selectedOptions }));
					// Wait for the price to be fetched.
					const price = await firstValueFrom(
						this.store.select(getItemPrices).pipe(
							map((prices) => prices.get(item.id) ?? new Pending()),
							first(isResolved),
						),
					);
					// Check if there was an error.
					if (isFailure(price)) {
						this.store.dispatch(
							reportItemError(new CartItemPricingError(item.id, itemSource, price.error?.body?.trace)),
						);
						return [item.id, {}] as const;
					}
					return [item.id, { currentPrice, newPrice: price.data }] as const;
				}),
			),
		);
		// Wait for product data to be fetched.
		await firstValueFrom(this.store.select(isLoadingProducts).pipe(first((isLoading) => !isLoading)));
		// Check if there was an error.
		if (await firstValueFrom(this.store.select(isErrorProducts)))
			throw new Error('Failed to fetch products');

		const productsById = await firstValueFrom(this.store.select(getAllProductsById));

		this.debug('Fetched products', productsById);
		let didUpdate = false;

		const updatedItems = items?.map((item) => {
			const fetchedProduct = productsById.get(item.product.id);
			if (fetchedProduct) {
				// Mark item as available if it was previously marked as not available.
				// E.g. if network request failed previously.
				this.store.dispatch(dismissItemError(new CartItemNotAvailableError(item.id, itemSource)));
			} else {
				// No fetched product found.
				this.debug('Product not fetched', item.product.sku);
				this.store.dispatch(reportItemError(new CartItemNotAvailableError(item.id, itemSource)));
				return item;
			}

			// Check if the printfile is available.
			if (!isPrintfileAvailable(item)) {
				this.store.dispatch(reportItemError(new CartItemPrintfileMissingError(item.id, itemSource)));
				return item;
			}

			const updatedItem = this.refreshItem(
				item,
				itemSource,
				fetchedProduct as CartItemProduct,
				itemPrices.get(item.id)!,
			);
			// Mapper returned undefined, no update needed.
			if (!updatedItem) {
				this.debug('No update needed for', item.product.sku);
				return item;
			}

			didUpdate = true;
			this.debug('Updated product', item.product.sku, updatedItem);
			return { ...updatedItem };
		});

		// Only update state if something changed.
		if (didUpdate) {
			this.debug('Updating items', updatedItems);
			this.store.dispatch(
				itemSource === ItemSource.ShoppingCart
					? setCartItems(updatedItems)
					: updateMyProducts(updatedItems),
			);
		}
	}

	/** Refreshes the cart item with the fetched product data. */
	private refreshItem(
		currentItem: api.CartItemWithProductDto,
		itemSource: ItemSource,
		fetchedProduct: CartItemProduct,
		{ currentPrice, newPrice }: { currentPrice?: api.ProductPriceDto; newPrice?: api.ProductPriceDto },
	): api.CartItemWithProductDto | undefined {
		const updatedItem = {
			...this.mergeSelectedOptions(currentItem, itemSource, fetchedProduct),
			product: this.mergeFetched(currentItem.product, fetchedProduct, refreshCartProductsKeys),
		};
		this.debug('Item v. Updated', currentItem.product.sku, { currentItem, updatedItem });

		const hasPriceChanged = !isObjectContained(currentPrice, newPrice);
		if (hasPriceChanged && newPrice) {
			this.debug('Price has changed', currentPrice ?? 'none', '=>', newPrice);
			// Only notify the user if the price has changed from an existing value.
			if (currentPrice)
				this.store.dispatch(reportItemError(new CartItemPriceChangeError(currentItem.id, itemSource)));
			return updatedItem;
		}

		// Don't update if data is equal to current state.
		if (
			isPlainObjectEqual(currentItem.options.selectedOptions, updatedItem.options.selectedOptions) &&
			isObjectContained(currentItem.product, updatedItem.product)
		)
			return undefined;

		// Something did change, but the user doesn't need to be notified.
		return updatedItem;
	}

	/**
	 * Merges the fetched data into the shopping cart object based on the specified keys.
	 * @param product The original shopping cart data.
	 * @param fetchedProduct The fetched data to be merged into the shopping cart.
	 * @param keys The keys specifying which properties to merge.
	 * @returns The new merged shopping cart object.
	 */
	private mergeFetched<T extends Record<string, unknown>>(
		product: T,
		fetchedProduct: T,
		keys: RefreshCartProductsKeys<T>,
	): T {
		const result: Record<string, unknown> = {};

		for (const property of keys) {
			if (typeof property === 'string') {
				if (property in fetchedProduct) {
					result[property] = fetchedProduct[property];
				}
			} else {
				const [keyName, subKeys] = property;
				if (keyName in fetchedProduct) {
					result[keyName] = this.mergeFetched(
						product[keyName] as T,
						fetchedProduct[keyName] as T,
						subKeys as RefreshCartProductsKeys<T>,
					);
				}
			}
		}

		return { ...product, ...result };
	}

	/**
	 * Compare each field in the current product's and cart item's selectableOptions, except order quantity:
	 * 1. If a field has been removed from selectableOptions, let's remove it from selectedOptions
	 * 2. If a field has been added to selectableOptions and it only has one selectableValue, let's add it to selectedOptions
	 * 3. If a field only has one selectableValue, and it previously had only one selectableValue, and the selectableValue has changed,
	 *    let's refresh the value in selectedOptions
	 * @param item The cart item to update
	 * @param itemSource The source of the cart item
	 * @param fetchedProduct The new product data
	 */
	private mergeSelectedOptions(
		item: api.CartItemWithProductDto,
		itemSource: ItemSource,
		fetchedProduct: api.CartItemProductDto,
	): api.CartItemWithProductDto {
		const selectedOptions = omit(item.options?.selectedOptions ?? {}, selectedOptionsNotUpdated);
		// Dropdown variables.
		const selectableOptions = omit(
			(fetchedProduct.properties?.selectableOptions ?? {}) as Record<
				keyof api.OrderProductSelectedOptionsDto,
				{ selectableValues: string[] }
			>,
			selectedOptionsNotUpdated,
		) as Record<keyof typeof selectedOptions, { selectableValues: string[] }>;
		this.debug('Product properties', fetchedProduct.product_properties);
		// Custom text fields.
		const textFields = new Set(
			Object.keys((fetchedProduct.properties?.textFields ?? {}) as Record<string, unknown>),
		);
		// If a field has been removed from selectableOptions & textFields, remove it from selectedOptions.
		const updatedSelectedOptions = Object.entries(selectedOptions).filter(
			([key]) => key in selectableOptions || textFields.has(key),
		);
		// Override the value if there is only one selectable value.
		for (const [keyStr, value] of Object.entries(selectableOptions)) {
			const key = keyStr as keyof typeof selectableOptions;
			const currentValue = selectedOptions[key] as string;
			if (value.selectableValues?.includes(currentValue)) {
				this.debug('Selected option already in selectable values', key, currentValue);
				continue;
			}

			if (value.selectableValues?.length === 1) {
				this.debug(
					'Selected option has only one selectable value, updating',
					key,
					'=>',
					value.selectableValues[0],
				);
				updatedSelectedOptions.push([key, value.selectableValues[0]]);
			} else {
				this.debug('Selected option has been updated', key);
				this.store.dispatch(reportItemError(new CartItemOptionsInvalidError(item.id, itemSource)));
				updatedSelectedOptions.push([key, undefined]);
			}
		}
		const newItem = {
			...item,
			options: {
				...item.options,
				selectedOptions: {
					...Object.fromEntries(updatedSelectedOptions),
					// Keep original selected options that are not updated.
					...pick(item.options?.selectedOptions ?? {}, selectedOptionsNotUpdated),
				},
			},
		};
		this.debug(
			'Merged selected options',
			item.options?.selectedOptions,
			'=>',
			newItem.options?.selectedOptions,
		);
		return newItem;
	}
}
